@import "./../../../app/mixins";

.root {
  margin: 13px -18px 0;
  padding: 8px 18px;
  background-color: $color-cyan;
  border: 1px solid transparent;

  @include primary-border-color();

  input[type="text"],
  select {
    width: 105px;
    padding: 2px 6px;
  }
}
